// extracted by mini-css-extract-plugin
var _1 = "tableStyle--alignEnd--Vk64Ux2x";
var _2 = "tableStyle--cell--d6fKtxFt";
var _3 = "tableStyle--filterCell--EZN82qNJ";
var _4 = "tableStyle--header--_lVNAN3K";
var _5 = "tableStyle--noBorderBottom--qz5ymIhA";
var _6 = "tableStyle--noContentCell--pB3P3mJ5";
var _7 = "tableStyle--paginationOptions--iAKaRqAH";
var _8 = "tableStyle--slider--lrJgVchy";
var _9 = "tableStyle--sorting--ilRm8hZI";
var _a = "tableStyle--space--smjtMjE1";
var _b = "tableStyle--subComponentCell--QFMOAZfc";
var _c = "tableStyle--tableContainer--MZTiETAt";
var _d = "tableStyle--tablePaper--YGtXMzVk";
export { _1 as "alignEnd", _2 as "cell", _3 as "filterCell", _4 as "header", _5 as "noBorderBottom", _6 as "noContentCell", _7 as "paginationOptions", _8 as "slider", _9 as "sorting", _a as "space", _b as "subComponentCell", _c as "tableContainer", _d as "tablePaper" }
