import { Accordion, AccordionDetails, AccordionSummary, Alert, Chip, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { FontAwesomeIcon, LoadingButton } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { stringifyQuery } from "../../helpers/utils";
import { useCurrentDataset } from "../../reducers/datasetManagement";
import * as styles from "./styles/index.scss";

const PAGE_SIZE = 10;
interface ClassInstance {
  instance: string;
  label?: string;
  classLabel?: string;
  instanceOf: string;
}

const factory = factories.compliant;
const Instances: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();
  const currentDs = useCurrentDataset();
  const applyPrefixes = useApplyPrefixes();
  const [instancesList, setInstancesList] = React.useState<ClassInstance[]>([]);
  const [page, setPage] = React.useState(0);

  // This component won't remount for every data object, so we should clear the pagination state helpers when the current resoruce changes
  React.useEffect(() => {
    setInstancesList([]);
    setPage(0);
  }, [currentClass]);

  const { data, error, loading } = useSparql<ClassInstance[]>(
    currentClass &&
      `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>
    prefix triply: <https://triplydb.com/Triply/function/>

    select
      ?instance
      ?instanceOf
      ?label
      ?classLabel
    where {
      bind(${termToString(factory.namedNode(currentClass))} as ?currentClass)
      {
        ?instance a ?currentClass
      } union {
        ?subClass rdfs:subClassOf+ ?currentClass .
        ?instance a ?subClass
      }
      bind(coalesce(?subClass, ?currentClass) as ?instanceOf)
      bind(triply:firstLabel(?instance) as ?label)
      bind(triply:firstLabel(?instanceOf) as ?classLabel)
    }
    limit ${PAGE_SIZE} offset ${page * PAGE_SIZE}
    `,
  );
  // Don't display if we've retrieved an empty page (but we can only do this check after loading)
  const canLoad =
    (loading || (page + 1) * PAGE_SIZE === instancesList.length) && instancesList.length % PAGE_SIZE === 0;
  React.useEffect(() => {
    if (data) {
      setInstancesList((list) => {
        return [...list, ...data];
      });
    }
  }, [data]);

  if (error) return <Alert severity="warning">Instances could not be loaded.</Alert>;

  if (loading && instancesList.length === 0) return <Skeleton height={54} variant="rectangular" />;

  if (!currentClass || !data || !data[0]) return null;

  return (
    <Accordion variant="outlined" className={styles.block}>
      <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon={["fas", "caret-down"]} />}>
        <Typography variant="h6" component="h2">
          Instances
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.instancesGrid}>
          {instancesList.map((instance) => {
            const classLabel = instance.classLabel || applyPrefixes(instance.instanceOf);
            return (
              <Link
                key={instance.instance}
                className={styles.instanceItem}
                to={{
                  pathname: `/${currentDs!.owner.accountName}/${currentDs!.name}/browser`,
                  search: stringifyQuery({ resource: instance.instance }),
                }}
                draggable
                onDragStart={(e) => e.dataTransfer.setData("text/plain", instance.instance)}
              >
                {instance.label || applyPrefixes(instance.instance)}
                {classLabel && <Chip size="small" label={classLabel} />}
              </Link>
            );
          })}
        </div>
        {canLoad && (
          <LoadingButton loading={loading} onClick={() => setPage((page) => page + 1)} className="mt-3">
            Load more
          </LoadingButton>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Instances;
